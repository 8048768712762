.App {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.input-box {
  margin: 10px 0;
  width: 80%;
  height: 50px;
  padding: 10px;
  font-size: 16px;
}

.select-box {
  margin: 10px 0;
  width: 80%;
  height: 40px;
  font-size: 16px;
}

.convert-button {
  margin: 10px 0;
  width: 80%;
  height: 40px;
  font-size: 16px;
  background-color: rgba(0, 94, 255, 0.776);
  color: white;
  border: none;
}
